<template>
  <div>
    <el-row>
      <el-col :span="8" :offset="8" style="text-align: center;margin-bottom: 5px;margin-top: 5px">
        <el-date-picker
            v-model="dateTime"
            align="right"
            type="date"
            placeholder="选择日期"
            format="yyyy年MM月dd日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions1" @change="showList">
        </el-date-picker>
      </el-col>
    </el-row>
    <div class="default-table">
      <div v-for="(incomeItem,idx) in dailyMap" :key="idx">
        <el-table :data="incomeItem.list" :show-header="incomeItem.show_header"
                  :cell-style="needStyle"
                  :summary-method="getSummaries"
                  show-summary border highlight-current-row>
          <el-table-column label="#" align="center" min-width="100">{{ incomeItem.title }}</el-table-column>
          <el-table-column prop="nickname" label="店铺名" min-width="160" align="center"></el-table-column>
          <el-table-column prop="amount_payment" label="GMV" min-width="160" align="right" header-align="center">
            <template slot-scope="scope">
              <span class="money" v-if="scope.row.amount_payment">
                {{ $utils.numberFormat(scope.row.amount_payment, 2) }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="amount_refund" label="申请退款金额" min-width="160" align="right" header-align="center">
            <template slot-scope="scope">
              <span class="money" v-if="scope.row.amount_refund">
                {{ $utils.numberFormat(scope.row.amount_refund, 2) }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="num_visitor" min-width="100" label="UV" align="right" header-align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.num_visitor">
                {{ $utils.numberFormat(scope.row.num_visitor) }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="c_unit_price" min-width="120" label="客单价" align="right" header-align="center">
            <template slot-scope="scope">
              <span class="money" v-if="scope.row.c_unit_price">
                {{ $utils.numberFormat(scope.row.c_unit_price, 2) }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="rate_payment" min-width="100" label="观看成交转化率" align="right" header-align="center">
            <template slot-scope="scope">
              <span class="rate" :title="`${scope.row.rate_payment}%`" v-if="scope.row.c_unit_price">
                {{ scope.row.c_unit_price ? $utils.numberFormat(scope.row.rate_payment, 2) : '-' }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-table :data="tableData" :show-header="false" :cell-style="needStyle" border highlight-current-row>
        <el-table-column label="#" align="center" min-width="100"><b>总计：</b></el-table-column>
        <el-table-column prop="nickname" label="店铺名" min-width="160" align="center"></el-table-column>
        <el-table-column prop="amount_payment" label="GMV" min-width="160" align="right" header-align="center">
          <template slot-scope="scope">
              <span class="money" v-if="scope.row.amount_payment">
                {{ $utils.numberFormat(scope.row.amount_payment, 2) }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount_refund" label="申请退款金额" min-width="160" align="right" header-align="center">
          <template slot-scope="scope">
              <span class="money" v-if="scope.row.amount_refund">
                {{ $utils.numberFormat(scope.row.amount_refund, 2) }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="num_visitor" min-width="100" label="UV" align="right" header-align="center">
          <template slot-scope="scope">
              <span v-if="scope.row.num_visitor">
                {{ $utils.numberFormat(scope.row.num_visitor) }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="c_unit_price" min-width="120" label="客单价" align="right" header-align="center">
          <template slot-scope="scope">
              <span class="money" v-if="scope.row.c_unit_price">
                {{ $utils.numberFormat(scope.row.c_unit_price, 2) }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="rate_payment" min-width="100" label="转化率" align="right" header-align="center">
          <template slot-scope="scope">
              <span class="rate" :title="`${scope.row.rate_payment}%`" v-if="scope.row.c_unit_price">
                {{ scope.row.c_unit_price ? $utils.numberFormat(scope.row.rate_payment, 2) : '-' }}
              </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  name: "DaySummary",
  computed: {
    tableData() {
      return [{
        amount_payment: this.statistic.total_gmv,
        amount_refund: this.statistic.total_refund,
      }]
    }
  },
  data() {
    return {
      dateTime: null,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      dailyMap: [
        { title: '抖音店', show_header: true, list: [] },
        { title: '淘宝店', show_header: false, list: [] },
        { title: '快手店', show_header: false, list: [] },
      ],
      statistic: { total_gmv: 0, total_refund: 0 },
      dataList: [
        {
          title: '化妆品',
          nickname: '优眯Yumi_',
          amount_payment: 888,
          amount_refund: 200,
          num_visitor: 200,
          c_unit_price: 100,
          rate_payment: 20
        },
        {
          title: '化妆品',
          nickname: 'JJG-YS',
          amount_payment: 777,
          amount_refund: 200,
          num_visitor: 200,
          c_unit_price: 100,
          rate_payment: 20
        },
      ]
    }
  },
  methods: {
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days);
      return day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // if (index < 1) {
        //   return
        // }
        if (index === 0) {
          sums[index] = '汇总:'
          return
        }
        if (['amount_payment', 'amount_refund'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // 格式化数据
          switch (column.property) {
            case 'amount_payment':
            case 'amount_refund':
              sums[index] = '￥' + this.$utils.numberFormat(sums[index], 2)
              break;
            default:
              sums[index] = this.$utils.numberFormat(sums[index], 0)
              break
          }
        }
      })

      return sums
    },
    //这是样式列表
    needStyle({ column, row }) {
      let cellStyle;
      cellStyle = '';
      switch (column.property) {
        case 'amount_payment':
          if (row[column.property] > 0)
            cellStyle = 'color:#409EFF';
          break;
        case 'amount_refund':
          if (row[column.property] > 0)
            cellStyle = 'color:#F56C6C';
          break;
        default:
          cellStyle = '';
      }
      return cellStyle;
    },
    async showList() {
      this.dailyMap = []
      this.statistic = {}
      if (this.dateTime) {
        // this.$notify.info(`日期：${ this.dateTime }`)
        let { daily_map, statistic } = await this.$api.getBsDailySummary({ date_time: this.dateTime })
        this.$nextTick(() => {
          this.dailyMap = daily_map
          this.statistic = statistic
        })
      }

    }
  },
  async created() {
    this.dateTime = this.getLastDays(1);
    await this.showList()
  }
}
</script>

<style scoped>
.title {
  text-align: center;
  line-height: 40px;
  height: 40px;
  font-size: 1.2rem;
  font-weight: 900;
  vertical-align: middle;
}
</style>
